import {
  Box,
  TextField,
  Button,
  InputAdornment,
  useMediaQuery,
  useTheme,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { Grid, Typography, makeStyles, createStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import CustomLink from '../../components/custom-link';
import ImageContainerBorder from '../../components/image-container/image-container-border';
import Layout from '../../components/layout';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SEO from '../../components/seo';
import RegistrationJSON from '../../data/registration.json';
import { registerNewPartner } from '../../validators/register-partner-validator';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import VideoContainerNew from '../../components/video-contanier/video-container-new';
import ExhibitionPdf from '../../../static/pdfs/GMIS2021-Exhibition-Opportunities.pdf';

const useStyles = makeStyles(() =>
  createStyles({
    textFieldLabelSpacing: {
      '& .MuiFormLabel-root': {
        marginLeft: '10px',
      },
    },
  })
);

const PartnerWithUsPage = ({ pageContext: { _partnerCategories } }) => {
  const classes = useStyles();
  const { country } = RegistrationJSON;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const formik = useFormik({
    onSubmit: () => {
      // Formik only handles state.
    },
    validateOnMount: true,
    validationSchema: registerNewPartner,
    initialValues: {
      /** Registration Type */
      '00N6e00000MeoNI': 'Partner with us',
      first_name: '',
      last_name: '',
      email: '',
      /** Company name */
      '00N5A00000LPeUN': '',
      /** Job title */
      '00N6e00000MeoNN': '',
      /** Country of residence */
      '00N6e00000MeoNS': '',
      /** Mobile number code */
      '00N5A00000LPOvc': '',
      /** Mobile number */
      '00N6e00000MeoNX': '',
    },
  });

  const handleSelect = (e, newValue) => {
    const { name } = newValue;
    formik.setFieldValue('00N6e00000MeoNS', name);
    formik.setFieldValue('00N5A00000LPOvc', country.find((_country) => _country.name === name)?.dialCode || '');
  };

  return (
    <Layout isSelectOveride>
      <SEO lang='en' title='Partner With Us | Global Manufacturing & Industrialisation Summit | GMIS 2022' />
      <SectionContainerLayoutWithFilter title='Why become a partner?'>
        <Grid container spacing={3}>
          {isMobile ? (
            <>
              <Grid item xs={12} md={6}>
                <ImageContainerBorder filename='GMIS-Partner-with-us' />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography paragraph color='textSecondary'>
                  Join an exclusive ecosystem made up of leaders from governments, some of the world’s largest global
                  organizations, manufacturing associations, academia and United Nations entities.
                </Typography>

                <Typography paragraph color='textSecondary'>
                  When you join the GMIS partner network, you become part of a fast-growing and dynamic global community
                  that is committed to advancing industrialization, digitalization and sustainable development.
                </Typography>
                <Typography paragraph color='textSecondary'>
                  At GMIS, we recognize the power of collaboration. Joining our partner network offers you the
                  opportunity to unlock business opportunities, showcase your products or services, gain access to
                  global trends, share your experience and knowledge, contribute to a global dialogue, participate in
                  global working groups and workshops, and be part of all new GMIS initiatives and projects.
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} md={6}>
                <Typography paragraph color='textSecondary'>
                  Join an exclusive ecosystem made up of leaders from governments, some of the world’s largest global
                  organizations, manufacturing associations, academia and United Nations entities.
                </Typography>
                <br />
                <br />
                <Typography paragraph color='textSecondary'>
                  When you join the GMIS partner network, you become part of a fast-growing and dynamic global community
                  that is committed to advancing industrialization, digitalization and sustainable development.
                </Typography>
                <br />
                <br />
                <Typography paragraph color='textSecondary'>
                  At GMIS, we recognize the power of collaboration. Joining our partner network offers you the
                  opportunity to unlock business opportunities, showcase your products or services, gain access to
                  global trends, share your experience and knowledge, contribute to a global dialogue, participate in
                  global working groups and workshops, and be part of all new GMIS initiatives and projects.
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <ImageContainerBorder filename='GMIS-Partner-with-us' />
              </Grid>
            </>
          )}
        </Grid>
      </SectionContainerLayoutWithFilter>
      <SectionContainerLayoutWithFilter title='Why Exhibit with us?'>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <VideoContainerNew
              height={isMobile ? '300px' : '400px'}
              src='https://www.youtube.com/watch?v=ICcJ4qx3s20'
              controls={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <Typography paragraph color='textSecondary'>
                  Showcase your products, services or innovations to local, regional and global leaders from both the
                  private and public sectors alongside one of the world’s most high-profile manufacturing and advanced
                  technology conferences.
                </Typography>
                {!isMobile && (
                  <>
                    <br />
                    <br />
                  </>
                )}
                <Typography paragraph color='textSecondary'>
                  The exhibition will be held across 6 days, from November 22-27, 2021 at Dubai Exhibition Center, and
                  will host the UAE’s first ‘Make It In The Emirates’ exhibition.
                </Typography>
                <br></br>
              </Grid>
              <Grid item xs={12}>
                <CustomLink linkUrl='mailto:exhibition@gmisummit.com' linkText='exhibition@gmisummit.com' external />
              </Grid>
              {/* <Grid item xs={12}>
                <Box mt={2}>
                  <Button
                    fullWidth={isMobile ? true : false}
                    variant='contained'
                    color='primary'
                    onClick={() => {
                      var a = document.createElement('a');
                      a.href = ExhibitionPdf;
                      a.setAttribute('download', '#GMIS2021-Exhibition-Opportunities.pdf');
                      a.click();
                    }}>
                    Download Exhibition Deck
                  </Button>
                </Box>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
      <SectionContainerLayoutWithFilter title='Contact Us'>
        <Grid
          component='form'
          action='https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8'
          method='POST'
          container
          spacing={8}>
          <input type='hidden' name='oid' value='00D5A0000016Qjw' />
          <input type='hidden' name='retURL' value='https://gmisummit.com/registration/partner-with-us/thank-you' />
          {process.env.NODE_ENV === 'development' && (
            <>
              <input type='hidden' name='debug' value='1' />
              <input type='hidden' name='debugEmail' value='shahbaz@eyesomex.com' />
            </>
          )}
          <Grid item container xs={12} spacing={1}>
            <Grid item xs={12} md={6} style={{ marginTop: '15px' }}>
              <FormControl fullWidth label='Registration Type'>
                <Select {...formik.getFieldProps('00N6e00000MeoNI')} IconComponent={KeyboardArrowDownIcon}>
                  <MenuItem value='Partner with us'>Partner with us</MenuItem>
                  <MenuItem value='Exhibit with us'>Exhibit with us</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                style={{ backgroundColor: 'transparent' }}
                className={classes.textFieldLabelSpacing}
                label='First Name'
                fullWidth
                autoComplete={'off'}
                error={formik.touched.first_name && formik.errors.first_name}
                {...formik.getFieldProps('first_name')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.textFieldLabelSpacing}
                label='Last Name'
                fullWidth
                autoComplete={'off'}
                error={formik.touched.last_name && formik.errors.last_name}
                {...formik.getFieldProps('last_name')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.textFieldLabelSpacing}
                label='Email'
                fullWidth
                autoComplete={'off'}
                error={formik.touched.email && formik.errors.email}
                {...formik.getFieldProps('email')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.textFieldLabelSpacing}
                label='Company Name'
                error={formik.touched['00N5A00000LPeUN'] && formik.errors['00N5A00000LPeUN']}
                {...formik.getFieldProps('00N5A00000LPeUN')}
                fullWidth
                autoComplete={'off'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                className={classes.textFieldLabelSpacing}
                label='Job Title'
                error={formik.touched['00N6e00000MeoNN'] && formik.errors['00N6e00000MeoNN']}
                {...formik.getFieldProps('00N6e00000MeoNN')}
                fullWidth
                autoComplete={'off'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='start'>
                      <Typography variant='body1'>*</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                onChange={handleSelect}
                options={country}
                autoComplete={'off'}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    className={classes.textFieldLabelSpacing}
                    {...params}
                    label='Country of Residence'
                    name='00N6e00000MeoNS'
                    error={formik.touched['00N6e00000MeoNS'] && formik.errors['00N6e00000MeoNS']}
                  />
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <TextField
                autoComplete={'off'}
                className={classes.textFieldLabelSpacing}
                label='Code'
                name='00N5A00000LPOvc'
                value={formik.values['00N5A00000LPOvc']}
              />
            </Grid>
            <Box clone pl={2}>
              <Grid item xs={10} md={4}>
                <TextField
                  className={classes.textFieldLabelSpacing}
                  label='Mobile Number'
                  autoComplete={'off'}
                  fullWidth
                  name='00N6e00000MeoNX'
                  error={formik.touched['00N6e00000MeoNX'] && formik.errors['00N6e00000MeoNX']}
                  {...formik.getFieldProps('00N6e00000MeoNX')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='start'>
                        <Typography variant='body1'>*</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Box>

            <Grid item container xs={12}>
              <Grid item xs={12} lg={2}>
                <Box my={5}>
                  <Button variant='contained' color='primary' fullWidth type='submit' disabled={!formik.isValid}>
                    SUBMIT
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SectionContainerLayoutWithFilter>
    </Layout>
  );
};

export default PartnerWithUsPage;
