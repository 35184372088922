import React from 'react';
import { CardMedia, Grid, Box, makeStyles, createStyles } from '@material-ui/core';
import ImageContainer from '.';
import colors from '../../constants/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    spacingColor: {
      position: 'relative',
      zIndex: 1,
      backgroundColor:
        theme.palette.type === 'dark'
          ? `${colors.primary.dark}${colors.transparency.button}`
          : `${colors.primary.light}${colors.transparency.button}`,
    },
    cornerCardBorder: {
      border: '3px solid',
      borderRadius: '4px',
      borderColor: `${theme.palette.primary.main} !important`,
      '&::before': {
        backgroundColor: theme.palette.background.default,
      },
      '&::after': {
        backgroundColor: theme.palette.background.default,
      },
    },
    playIcon: {
      zIndex: 2,
      fontSize: '50px',
      color: theme.palette.background.default,
    },
  })
);

const ImageContainerBorder = (props) => {
  const classes = useStyles();

  return (
    <Grid container>
      <Grid item style={{ cursor: 'default' }} className={`video cornerBorder ${classes.cornerCardBorder}`} xs={12}>
        <Box p={1} className={classes.spacingColor}>
          <Box>
            {props.filename ?  <ImageContainer
              component='img'
              filename={props.filename}
              alt={props.alt || '#GMIS2021'}
            /> :  <CardMedia
              component='img'
              image={props.image}
              alt={props.alt || '#GMIS2021'}
            />}
           
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ImageContainerBorder;
